/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function () {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeMoveInReadyCtrl', ['$scope', '$filter',
      function ($scope, $filter) {

        $scope.filteredHomes = [];

        $scope.sortColumn = 'price';
        $scope.sortName = '';
        $scope.sortReverse = true;

        $scope.toggleType = function (kind, order) {
          $scope.sortColumn = kind;
          $scope.sortReverse = order;
          $scope.updateSortName();

        };

        $scope.sortsParams = [{
          name: 'By Highest Price',
          value: {
            type: 'price',
            descending: true
          }
        }, {
          name: 'By Lowest Price',
          value: {
            type: 'price',
            descending: false
          }
        }, {
          name: 'By Most Bedrooms',
          value: {
            type: 'beds',
            descending: true
          }
        },{
          name: 'By Least Bedrooms',
          value: {
            type: 'beds',
            descending: false
          }
        }, {
          name: 'By Highest Sq. Ft.',
          value: {
            type: 'sqft',
            descending: true
          }
        }, {
          name: 'By Lowest Sq. Ft.',
          value: {
            type: 'sqft',
            descending: false
          }
        }];

        $scope.updateSortName = function() {
          let matchingSortParam = $scope.sortsParams.find(function(param) {
            return param.value.type === $scope.sortColumn && param.value.descending === $scope.sortReverse;
          });

          $scope.sortName = matchingSortParam ? matchingSortParam.name : '';
        }

        $scope.updateSortName();
        $scope.filtersParams = {
          bed: [],
          bath: [],
          price: [],
          type: [],
          collection: [],
          stories: []
        };

        $scope.filters = {
          price: 'all',
          bed: 'all',
          bath: 'all',
          type: 'all',
          collection: 'all',
          stories: 'all'
        }

        $scope.specAggregates = {
          bed: [],
          bath: [],
          price: [],
          type: [],
          collection: []
        };

        var defaults = {
          filterParam: {
            name: 'All',
            value: 'all'
          }
        };

        $scope.filtersParams.price.push(defaults.filterParam);
        $scope.filtersParams.bed.push(defaults.filterParam);
        $scope.filtersParams.bath.push(defaults.filterParam);
        $scope.filtersParams.type.push(defaults.filterParam);
        $scope.filtersParams.collection.push(defaults.filterParam);

        $scope.$watch('filters', function(filters, oldFilters) {
          if (filters === oldFilters) {
            return;
          }

          $scope.filteredHomes = $filter('homeFilter')($scope.community.homes, $scope.filters);
        }, true);

        const chunk = (input, size) => {
          return input.reduce((arr, item, idx) => {
            return idx % size === 0
              ? [...arr, [item]]
              : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
          }, []);
        };

        $scope.communityPromise
          .then(function () {
            // $scope.moveInReady = $scope.community.homes;
            // $scope.moveInReadyGroup = chunk($scope.moveInReady, 3);

            angular.forEach($scope.community.homes, function(home) {
              if(home.plan && typeof home.plan.collection !== 'undefined' && home.plan.collection) {
                $scope.specAggregates.collection.push(home.plan.collection.name);
              }
            });

            Object.keys($scope.specAggregates).forEach(function(key) {
              switch (key) {
                case 'price':
                  var minPrice = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxPrice = Math.max.apply(null, $scope.specAggregates[key]);
                  minPrice = Math.ceil(minPrice / 100000) * 100000;
                  maxPrice = (Math.ceil(maxPrice / 100000) * 100000);

                  for (var i = minPrice; i <= maxPrice; i += 100000) {
                    $scope.filtersParams.price.push({
                      name: 'Under $' + i.toLocaleString(),
                      value: i
                    });
                  }
                  break;

                case 'bed':
                  var minBeds = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxBeds = Math.max.apply(null, $scope.specAggregates[key]);

                  for (var i = minBeds; i <= maxBeds; i++) {
                    $scope.filtersParams.bed.push({
                      name: i + '+',
                      value: i
                    });
                  }
                  break;
                case 'bath':
                  var minBaths = Math.min.apply(null, $scope.specAggregates[key]);
                  var maxBaths = Math.max.apply(null, $scope.specAggregates[key]);

                  for (var i = minBaths; i <= maxBaths; i++) {
                    $scope.filtersParams.bath.push({
                      name: i + '+',
                      value: i
                    });
                  }
                  break;
                case 'type':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    $scope.filtersParams.type.push({
                      name: item,
                      value: item
                    });
                  });
                  break;
                case 'collection':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    let minSqft = Infinity;
                    let maxSqft = -Infinity;
                    let minBeds = Infinity;
                    let maxBeds = -Infinity;
                    let minBaths = Infinity;
                    let maxBaths = -Infinity;
                    let minPrice = Infinity;

                    $scope.community.homes.forEach(home => {
                      try {
                          if (home.plan.collection.name === item) {
                              minSqft = Math.min(minSqft, home.plan.sqft_low);
                              maxSqft = Math.max(maxSqft, home.plan.sqft_high);
                              minBeds = Math.min(minBeds, home.plan.bed_low);
                              maxBeds = Math.max(maxBeds, home.plan.bed_high);
                              minBaths = Math.min(minBaths, home.plan.bath_low);
                              maxBaths = Math.max(maxBaths, home.plan.bath_high);
                              if (home.plan.price < minPrice) {
                                  minPrice = Math.min(minPrice, home.plan.price);
                              }
                          }
                      } catch (error) {
                          console.error("An error occurred with the collection filter: ", error);
                      }
                  });

                    let homeCopy;
                    if(item == 'Paired Patio Homes Collection') {
                      homeCopy = 'Paired Patio Homes';
                    } else if(item === 'Patio Homes Collection'){
                      homeCopy = 'Patio Homes';
                    } else if(item === 'Midtown Collection'){
                      homeCopy = 'Townhomes';
                    } else {
                      homeCopy = 'Single Family Homes';
                    }
                    $scope.filtersParams.collection.push({
                        name: item,
                        value: item,
                        bedRange: `${minBeds}-${maxBeds}`,
                        bathRange: `${minBaths}-${maxBaths}`,
                        sqftRange: `${minSqft}-${maxSqft}`,
                        price: minPrice,
                        copy: `${homeCopy} Priced From ${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(minPrice)}`
                    });

                  });
                  $scope.filtersParams.collection.sort((a, b) => {
                    return b.price - a.price;
                  });
                  break;
                case 'stories':
                  $scope.specAggregates[key] = _.uniq($scope.specAggregates[key]);
                  $scope.specAggregates[key].sort();
                  angular.forEach($scope.specAggregates[key], function(item) {
                    $scope.filtersParams.stories.push({
                      name: item + " Story",
                      value: item
                    });
                  });
                  break;

              }
            });

            $scope.filteredHomes = $filter('homeFilter')($scope.community.homes, $scope.filters);
          });

        $scope.selectFilter = function(type, value) {
          $scope.filters[type] = value;
        }

        $scope.filterByStory = function(storyCount) {
            if (storyCount === 1) {
                if ($scope.filters.stories && $scope.filters.stories.value === 1) {
                    $scope.filters.stories = {};
                } else {
                    $scope.filters.stories = { value: 1 };
                }
            } else if (storyCount === 'multi') {
                if ($scope.filters.stories && $scope.filters.stories.value === 'multi') {
                    $scope.filters.stories = {};
                } else {
                    $scope.filters.stories = { value: 'multi' };
                }
            } else {
                $scope.filters.stories = {};
            }
        };

      }
    ])
    .filter('homeFilter', function() {
      return function(homes, filters) {
        return homes.filter(function(home) {
          var valid = true;

          Object.keys(filters).forEach(function(key) {
            if (filters[key]['value'] && filters[key]['value'] !== 'all') {
              switch (key) {
                case 'bed':
                  if (filters[key]['value'] < home.plan.bed_low || filters[key]['value'] > home.plan.bed_high) {
                    valid = false;
                  }
                  break;
                case 'bath':
                  if (filters[key]['value'] < home.plan.bath_low || filters[key]['value'] > home.plan.bath_high) {
                    valid = false;
                  }
                  break;
                case 'price':
                  if (home.price > filters[key]['value']) {
                    valid = false;
                  }
                  break;
                case 'type':
                  if (home.plan.productType.name != filters[key]['value']) {
                    valid = false;
                  }
                  break;
                case 'collection':
                  if (sget(['plan', 'collection', 'name'], home) != sget([key, 'value'], filters)) {
                    valid = false;
                  }
                  break;
                case 'stories':
                    if (filters[key] && filters[key]['value'] === 'multi') {
                        if (home.plan.floors <= 1) {
                            valid = false;
                        }
                    } else if (filters[key] && home.plan.floors !== filters[key]['value']) {
                        valid = false;
                    }
                    break;
              }
            }
          });

          return valid;
        });


      };
    })
    ;
}());
